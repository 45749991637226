<template>
  <div class="school-widget"
       :class="[classes]">
    <div class="input-group">
      <div class="input-group-prepend"
           v-if="prepend">
        <div class="input-group-text">School</div>
      </div>
    
      <v-select class="form-control school-widget-select"
                :class="[classes]"
                v-model="selectedSchoolId"
                :options="options"
                :reduce="school => school.school_id"
                label="school_name"
                placeholder="Select a school"
                :disabled="disabled" />

      <div class="input-group-append"
            v-if="allowAdd && breakpoint === 'xl'">
        <button class="btn btn-primary btn-h3-small"
                title="Add New School"
                type="button"
                @click="openAddSchoolModal">
          Add School
        </button>
      </div>

      <div class="invalid-feedback" id="school"
           v-if="classes == 'is-invalid'">
        <span>School is required.</span>
      </div>
    </div>
    <div class="button-wrapper text-center">
      <button class="btn btn-primary btn-h3-small"
              title="Add New School"
              type="button"
              @click="openAddSchoolModal"
              v-if="breakpoint !== 'xl'">
        Add School
      </button>
    </div>
  </div>
</template>

<script>
  const AddSchool = () => import('../modals/AddSchool.vue');
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    name  : 'SchoolWidget',
    props : {
      prepend : {
        type    : Boolean,
        default : false,
      },
      initial : {
        type    : Number,
        default : null,
      },
      allowAdd : {
        type    : Boolean,
        default : false,
      },
      disabled : {
        type    : Boolean,
        default : false,
      },
      classes : {
        type    : String,
        default : '',
      },
    },
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        options          : [],
        selectedSchoolId : null,
      };
    },
    created() {
      this.getSchools();
    },
    mounted() {
      if (this.initial)
        this.selectedSchoolId = this.initial;
    },
    watch : {
      'selectedSchoolId' : {
        handler(val) {
          this.$emit('changed', val);
        },
        deep : true,
      },
    },
    methods : {

      /**
       * Get Schools
       */
      getSchools() {
        this.$http.get('api/school/all')
          .then(response => {
            this.options = response.data;
            this.selected = this.initial;
          });
      },

      /**
       * Open Add School Modal
       */
      openAddSchoolModal() {
        this.$modal.show(AddSchool, {}, {
          height   : 'auto',
          width    : this.windowWidth >= 992 ? '80%' : '100%',
          maxWidth : this.windowWidth >= 992 ? 800 : 600,
          adaptive : true,
        }, {
          'before-close' : async () => {
            await this.getSchools();
            if (localStorage.selectedSchoolId) {
              this.selectedSchoolId =  parseInt(localStorage.selectedSchoolId);
              localStorage.removeItem('selectedSchoolId');
            }
          },
        });
      },

      /**
       * Reset Selected School
       */
      resetSelectedSchool() {
        this.selectedSchoolId = null;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/shared/school-widget";
</style>